import React from 'react';
import './visualize.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'

function visualize(props) {
    const english = props.english
    const spanish = props.spanish

    return (
        
        <div id='visualize-container'>
            <div id='visualize-left'></div>
            <div id='carousel-container'>
            {/* <div id='visualize-me-header'>Visualize Me</div> */}
            
            {english && (<div id='visualize-me-header'>Visualize Me</div>)}
            {spanish && (<div id='visualize-me-header'>Visualízame</div>)}

            <Carousel showThumbs={false} autoPlay 
            // dynamicHeight={true}
            >
                 <div>
                    <img class='landscape' alt="" src="https://i.ibb.co/DKrBdjj/IMG-8279.webp" />
                </div>
                <div>
                    <img class='portrait' alt="" src="https://i.ibb.co/bg53LzS/8826585-A-44-D0-4104-983-C-D63-D4-E58-DCB9.jpg" />
                </div>
                <div>
                    <img class='portrait' alt="" src="https://i.ibb.co/SKDFm6k/IMG-2791.jpg" />
                </div>
                <div>
                    <img class='portrait' alt="" src="https://i.ibb.co/261MDj2/23830785-2879-4-B7-C-B8-EE-4-ACD434-C991-E.jpg" />
                </div>
                <div>
                    <img class='portrait' alt="" src="https://i.ibb.co/dMQKCKm/IMG-8277.webp" />
                </div>
                <div>
                    <img class='portrait' alt="" src="https://i.ibb.co/Rc7GL5x/IMG-8289-1.webp" />
                </div>
                <div>
                    <img class='landscape' alt="" src="https://i.ibb.co/JnH632W/IMG-8273.webp" />
                </div>
            
                <div>
                    <img class='portrait' alt="" src="https://i.ibb.co/PFzGwX3/IMG-8223.jpg" />
                </div>
            </Carousel>
            
            </div>
            <div id='visualize-right'></div>
        </div>)
}
export default visualize;

