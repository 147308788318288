import React from 'react';
import './middlesection.css';
import { Parallax } from "react-scroll-parallax";
import About from './about'
// import Services from './services';
import Treat from './treat';
import Book from './book';
import Policy from './policy';
import Visualize from './visualize'

// import { ParallaxBanner } from 'react-scroll-parallax';


function middlesection(props) {
    const english = props.english
    const spanish = props.spanish


    return (
        <div>
            <div className="middlesection">
                <div id='imagecontainer'>
                    <Parallax speed={-50}>
                        <img id='hero' src='https://i.ibb.co/YRgfZ8X/Untitled-design.png' alt='hero'></img>
                    </Parallax>
                    <div id='name'>
                    NATALIA CAKES
                    </div>
                </div>
                <div class='section' id="about">
                    <About english={english} spanish={spanish}/>
                </div>
                {/* <div class='section' id="services">
                    <Services/>
                </div> */}
                <div class='section' id="visualize">
                    <Visualize english={english} spanish={spanish}/>
                </div>
                <div class='section' id="treat">
                    <Treat english={english} spanish={spanish}/>
                </div>
                <div class='section' id="serve">
                    <Book english={english} spanish={spanish}/>
                </div>
                <div class='section' id="policies">
                    <Policy english={english} spanish={spanish}/>
                </div>
            </div>

        </div>

    );
}

export default middlesection;

