import React from 'react';
import './policy.css';

function policy() {

    return (
        <div>
            <div id='policy-header'></div>
            <div id='policy-main'>
                <div id='policy-left'></div>
                    <div id='policy-center'>
                <div className='link-class'><a href='https://linktr.ee/nataliacakes'>Linktree</a></div>
                {/* <div className='link-class'><a href=''>Onlyfans</a></div>
                <div className='link-class'><a href=''>TER Reviews</a></div> */}

                        </div>
                <div id='policy-right'></div>
            </div>
        </div>)
}
export default policy;

