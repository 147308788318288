import React from 'react';
import './treat.css';

function treat(props) {
    const english = props.english
    const spanish = props.spanish

    return (
        <div>
            {english && (<div id='treat-me-header'>Rates</div>)}
            {spanish && (<div id='treat-me-header'>Precios</div>)}

            <div id='treat-me-main'>
                <div id='treat-me-left'></div>

                {english && (<div id='mobile-styler'>
                    <div id='treat-me-center-left'>
                       
                    </div>
                    <div id='treat-me-center-right'>
                        {/* <h2 class='cityname'>Las Vegas</h2>
                        <h3>Next dates: May 10 - May 26</h3> */}
                        <p id='treat-me-text'>In Las Vegas/Nevada, I am affiliated with and available at only Alien Cathouse legal brothel for incall services. At the brothel, a booking will only be formally held if a $500 deposit is placed, and is transferable if the booking is delayed.<br></br><br></br>
                        
                        Due to county law, I am not able to advertise or disclose my Alien Cathouse prices online. County laws require that prices must be agreed upon verbally at the brothel. Generally, my Alien Cathouse rates are about double my NYC rates.

<br></br><br></br>The Alien Cathouse legal brothel is located in Amargosa Valley, NV about 90 minutes from Las Vegas. (2703 US-95, Amargosa Valley, NV, 89020)
                        </p>
                    </div>
                </div>)}


                {spanish && (<div id='mobile-styler'>
                    <div id='treat-me-center-left'>
                        
                    </div>
                    <div id='treat-me-center-right'>
                        {/* <h2 class='cityname'>Las Vegas</h2> */}
                        {/* <h3>May 10 - May 26</h3> */}
                        <p id='treat-me-text'>
                        En Las Vegas/Nevada, estoy afiliada y disponible solo en el burdel legal Alien Cathouse para
servicios de incall/visita. En el burdel, una reserva solo se realizará formalmente si se realiza un
depósito de $ 500 y es transferible si la reserva se retrasa.<br></br><br></br>
                        
Debido a la ley del condado, no puedo anunciar ni divulgar mis precios de Alien Cathouse en
línea. Las leyes del condado requieren que los precios deben ser revelados verbalmente en el
burdel. En general, mis precios en Alien Cathouse son aproximadamente el doble de mis
precios en la ciudad de Nueva York.
<br></br><br></br>El burdel legal Alien Cathouse está ubicado en Amargosa Valley, NV, a unos 90 minutos de Las
Vegas. (2703 US-95, Amargosa Valley, NV, 89020)
                        </p>
                    </div>
                </div>)}

                <div id='treat-me-right'></div>
            </div>
        </div>)
}
export default treat;

