import React from 'react';
import './book.css';

function book(props) {

    const english = props.english
    const spanish = props.spanish


    return (
        <div>
            {english && (<div id='book-me-header'>Contact</div>)}
            {spanish && (<div id='book-me-header'>Contacto</div>)}

            {english && ( <div id='book-me-main'>
                <div id='book-me-left'></div>
                <div id='mobile-styler'>
                    <div id='book-me-center-left'>
                
                    </div>
                    <div id='book-me-center-right'>
                        {/* <h2 class='cityname'>Las Vegas</h2> */}
                        <div>For details you can email me at: natalia@aliencathouse.com or nataliacakess@proton.me<br></br>To book, please contact the Cathouse directly at (775) 372-5678 </div><br></br> 
                    </div>
                </div>
                <div id='book-me-right'></div>
            </div>)}

            {spanish && ( <div id='book-me-main'>
                <div id='book-me-left'></div>
                <div id='mobile-styler'>
                    <div id='book-me-center-left'>
              
                    </div>
                    <div id='book-me-center-right'>
                        {/* <h2 class='cityname'>Las Vegas</h2> */}
                        <div>Para obtener más información, puede enviarme un correo electrónico a:
audreywrightxoxo@gmail.com</div><br></br>
                        <div>Para reservar, comuníquese directamente con Cathouse al (775) 372-5678</div>
                    </div>
                </div>
                <div id='book-me-right'></div>
            </div>)}
        </div>)
}
export default book;

