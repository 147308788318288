import React from 'react';
import './about.css';
// import { Parallax } from "react-scroll-parallax";

function about(props) {
    const english = props.english
    const spanish = props.spanish

    return (
        <div>
            <div id='about-main'>
                <div id='about-left'></div>
                <img id='mobile-about-image' src='https://i.ibb.co/z2Xy5Zg/IMG-8245.webp' alt='hero'></img>
                <div id='about-center'>



                    {english && (
                        <div id='about-center-left'>
                            <div id='about-text'><span id='intro-line'>
                                ¡Hola! I'm a vibrant, light-skin, Mexican woman with a zest for life and a jaw dropping culo.
                            </span>
                                <br></br><br></br>

                                I was raised among the colorful landscapes of Mexico but now live among the rugged beauty of the Rocky Mountains. I’m an activist and freedom fighter with so much passion. Todo la yerba, me la fumaré.
                                 My laughter is infectious, and I thrive on spreading joy wherever I go. With passionate eyes that mirror my fiery spirit, I believe in living life to the fullest. I'm dedicated to fitness, embracing the outdoors, and nurturing both my body and soul. Let's embark on an adventure together, where laughter and passion intertwine amidst the majestic dessert.
<br></br><br></br>
                                Let me wrap my beautiful long legs around you and bring you into my fantasy world. So, tell me, what are your deepest desires?
                            </div>
                        </div>
                    )}



                    {spanish && (
                        <div id='about-center-left'>
                            <div id='about-text'><span id='intro-line'>Hola! Soy una mujer mexicana vibrante, de piel clara, con ganas de vivir y un culo que te deja boquiabierto.
</span> <br></br><br></br>
                            Crecí entre los coloridos paisajes de México, pero ahora vivo entre la belleza escarpada de las Montañas Rocosas. Soy un activista y luchador por la libertad con mucha pasión. Todo la yerba, me la fumaré.
<br></br>
Mi risa es contagiosa y me encanta difundir alegría dondequiera que vaya. Con ojos apasionados que reflejan mi espíritu ardiente, creo en vivir la vida al máximo. Me dedico al fitness, a disfrutar del aire libre y a cuidar mi cuerpo y mi alma. Embárquemonos juntos en una aventura, donde la risa y la pasión se entrelazan en medio del majestuoso postre.
<br></br><br></br>
Déjame envolverte con mis hermosas y largas piernas y llevarte a mi mundo de fantasía. Entonces, dime, ¿cuáles son tus deseos más profundos?
                            </div>
                        </div>
                    )}

                    <div id='about-center-right'>
                        <img id='desktop-about-image' src='https://i.ibb.co/z2Xy5Zg/IMG-8245.webp' alt='hero'></img>
                        {/* <Parallax speed={10}>
                        </Parallax> */}
                    </div>
                </div>
                <div id='about-right'></div>
            </div>
        </div>
    );
}

export default about;
