import React, { useState } from 'react'
import Header from './components/header'
import Middlesection from './components/middlesection'
import { ParallaxProvider } from "react-scroll-parallax";
import './app.css';

function App() {
  const [age, setAge] = useState(true);
  const [english, setEnglish] = useState(true);
  const [spanish, setSpanish] = useState(false);

  const ageHandler = () => {
    setAge(false);
};
  
const ageHandlerSi = () => {
  setAge(false);
  setEnglish(false);
  setSpanish(true);

};

  return (
    <ParallaxProvider>
    <div className="App">
      {/* <header className="App-header"> */}

      {age && (
                <div id='ageCheck'>
                <div id='ageCheckText'>
                  {/* <img id='jabroni' alt='lol 'src='https://assets.untappd.com/site/beer_logos_hd/beer-1530729_2c6c6_hd.jpeg'/> */}
                  <div id='ageCheckCopy'>Please confirm are you over 21.</div>
                  <div id='ageCheckCopy'>Para ver este página web, por favor confirme que tiene más de 21 años.</div>
                  <div id='yesButton' onClick={ageHandler}>🇺🇸  Yes</div>
                  <div id='yesButton' onClick={ageHandlerSi}>🇪🇸  Sí</div>
                </div>    
              </div>
            )}

      <Header />
      <Middlesection english={english} spanish={spanish}/>
      
      {/* </header> */}
    </div>
    </ParallaxProvider>
  );
}

export default App;
