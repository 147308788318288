import React from 'react';
import { Link } from 'react-scroll'
import './header.css';

function header() {
    return (
        <div className="header">
            <ul id='header-links' >
                <li className='hover'><Link activeClass="active" to="about" spy={true} smooth={true}>ABOUT</Link></li>
                <li className='hover'><Link to="visualize" spy={true} smooth={true}>GALLERY</Link></li>
                <li className='hover'><Link to="treat" spy={true} smooth={true}>RATES</Link></li>
                <li className='hover'><Link to="serve" spy={true} smooth={true}>CONTACT</Link></li>
                <li className='hover'><Link to="policies" spy={true} smooth={true}>LINKS</Link></li>
            </ul>
        </div>
    );
}

export default header;
